@use 'colors';
@import "../../node_modules/bootstrap/scss/bootstrap";
@import url('https://fonts.googleapis.com/css2?family=Montserrat:wght@400;500;600&display=swap');

body {
  height: 100vh;
  margin: 0;
  font-family: 'Montserrat', sans-serif;
  font-weight: 400;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  color: colors.get(neutrals, grey-900);
  background-color: colors.get(neutrals, grey-050);
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.App {
  text-align: center;
}


header {
  height: 8em;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}


.logo {
  height: 4em;
  margin-top: 2em;
  margin-left: 2em;
}

.nav {
  margin-top: 2em;
  margin-right: 2em;
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
}

@media (max-width: 900px) {
  header {
    flex-direction: column;
    align-items: center;
  }

  .nav {
    margin: unset;
  }
}

.nav-masthead .nav-link {
  padding: .25rem 0;
  font-weight: 500;
  font-size: 1.3rem;
  background-color: transparent;
  color: colors.get(neutrals, grey-900);
  border-bottom: .25rem solid transparent;
  height: 2em;
}

@media (max-width: 900px) {
  .nav-masthead .nav-link {
  font-size: 1rem;
  }
}

.nav-masthead .nav-link:hover {
  border-bottom-color: colors.get(neutrals, grey-050);
}

.nav-masthead .nav-link + .nav-link {
  margin-left: 1rem;
}

.nav-masthead .active {
  border-bottom-color: colors.get(primary, yellow-vivid-400);
  font-weight: 700;
}

#hi {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
  flex-wrap: nowrap;
  font-weight: 600;
  font-size: 2.5rem;
  text-align: left;
}

@media (max-width: 900px) {
  #hi {
    font-size: 1.2rem;
    white-space: nowrap;
  }
}

.typewriter {
  overflow: hidden;
  border-right: .15em solid colors.get(primary, yellow-vivid-400);
  white-space: nowrap;
}

div#root {
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.projects {
  display: flex;
  flex-direction: column;
  gap: 1em;
}

.project-category {
  display: flex;
  flex-direction: column;
}

.projects-grid {
  display: flex;
  flex-direction: row;
  align-items: stretch;
  align-content: stretch;
  flex-wrap: wrap;
  justify-content: center;
  gap: 1em;
}

.projects-grid a {
  height: auto;
}

.project-card {
  height: 100%;
  width: 18em;
  color: colors.get(neutrals, grey-900);
	display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
	text-decoration: none;
	position: relative;
	background-color: colors.get(neutrals, grey-050);
	cursor: pointer; 
  border-radius: 0;
	&:before {
		position: absolute; 
		left: 0;
		bottom: 0;
		content: "";
		display: block;
		width: 100%;
		height: 100%;
		background-color: colors.get(neutrals, grey-900);
		transform-origin: 0 top 0;
		transform: scaleY(0);
		transition: .3s ease-out;
	}
	
	&:hover {
		* {
			color: $white;
		}
		&:before {
			transform: scaleY(1);
		}
	}
}

.project-card * {
	position: relative;
	transition: .4s ease-out;
}

@media (min-width: 600px) {
  .projects-grid { grid-template-columns: repeat(2, 1fr); }
}

@media (min-width: 900px) {
  .projects-grid { grid-template-columns: repeat(3, 1fr); }
}

@media (max-width: 900px) {
  .project-card {
    max-width: unset;
  }
  .projects-grid {
    justify-content: center;
    align-content: stretch;
  }
}

.card-body {
  padding: 1em 0 0 0;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  width: 100%;
}

a {
  text-decoration: none;
}

.card-image {
  display: flex;
  justify-content: center;
  align-items: center;

  width: 100%;
  height: 15em;
  padding: 1em 2em;
  border-radius: 0;
  background-color: colors.get(neutrals, grey-100);
}

.burger
{
    display: none;
}
 
@media all and (max-width: 991px)
{
    nav
    {
        height: 60px;
    }
}

.contact {
  display: flex;
  flex-direction: column;
  gap: 1rem;
  margin-top: 1rem;
  align-items: stretch;
}

.contact-me {
  background-color: colors.get(supporting, teal-700);
  border: none;
  display: flex;
  flex-direction: row;
  justify-content: space-around;
  align-items: center;
  height: 3rem;
  width: 14rem;
}

.contact-me:active {
  background-color: colors.get(supporting, teal-800);
  border: none;
}

.contact-me:hover {
  background-color: colors.get(supporting, teal-900);
  border: none;
}

.contact-me:focus, .contact-me:focus:active {
  background-color: colors.get(supporting, teal-800);
  box-shadow: 0 0 0 0.25rem colors.get(supporting, teal-400);
  border: none;
}

.contact-me span {
  margin-left: 0.5em;
  font-size: 20px;
}

.contact-me svg {
  font-size: 15px;
}

.footer {
  display: flex;
  flex-direction: row;
  justify-content: center;
  font-size: xx-large;
  gap: 1rem;
}

.footer a {
  color: colors.get(neutrals, grey-900);
}

.footer a:hover {
  color: colors.get(neutrals, grey-600);
}

.thumbnail-keywords {
  font-style: italic;
}

.card-title {
  font-weight: bold;
}

.blog {
  display: flex;
  flex-direction: column;
  text-align: justify;
  max-width: 800px;
  align-items: flex-start;
  min-height: 100%;
}

.intro {
  text-align: justify;
  font-size: x-large;
  margin: 0;
}

@media (max-width: 900px) {
  .intro {
    font-size: 1rem;
    white-space: normal;
  }
}

.container {
  display: flex;
  flex-direction: column;
  margin: auto;
}

.spinner {
  margin: auto
}

.phone-number {
  color: colors.get(neutrals, grey-050);
}